<template>
  <div class="abilityDetails">
    <div class="content">
      <!-- 时间 -->
      <div class="time top">
        <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)" />
        <div class="current" @click="handPoulp">{{ current }}</div>
        <svg-icon v-show="current < today" icon-class="back" class="arr arr-right" @click="getDate(true)" />
      </div>
      <!-- 计时 -->
      <div class="timing">
        <div class="num" style="text-align: center;font-size: 25px;">血压</div>
        <div class="num" style="text-align: center;font-size: 20px;">{{ currentTime }}</div>
        <div class="number">
          <span>{{ currentPinch }}</span>
          <span v-if="currentStretch">/</span>
          <span v-if="currentStretch">{{ currentStretch }}</span>
          <span class="millimeter">毫米汞柱</span>
        </div>
      </div>
      <!-- 图表 -->

      <div class="charts">
        <div id="bloodecharts" class="bloodecharts"></div>
      </div>
      <!-- tips -->
      <div class="Tips">
        <div class="shrink">收缩压</div>
        <div class="diastole">舒张压</div>
      </div>
      <!-- button -->
      <div class="buttons">
        <div class="btn">
          <div class="average">平均值</div>
          <div class="num" style="text-align: center;">{{ valuepinchAvg }}/{{ valuestretchAvg }}<span
              class="mercury">毫米汞柱</span></div>
        </div>
        <div class="btn btn1">
          <div class="pressure">压力范围</div>
          <div class="num" style="text-align: center;">
            <span>{{ valuepinchMax }}</span><span v-if="valuestretchMin">/</span><span v-if="valuestretchMin">{{
              valuestretchMin }}</span>
            <span class="mercury">毫米汞柱</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <van-calendar 
      v-model="showBoolPicker" 
      @confirm="onConfirm"
      @cancel="showBoolPicker = false"
    /> -->
    <van-calendar v-model="showBoolPicker" :min-date="minDate" @confirm="onConfirm" color="#9966FF"
      :style="{ height: '420px' }" />
  </div>
</template>

<script>

import { current } from '@/util/validate'
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { formatDate } from '@/utils/prototype/date.js'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../../components/tabbar/HistoryBar.vue'

// 注册必须的组件
echarts.use([GridComponent, CanvasRenderer, LineChart, LegendComponent, TooltipComponent])
export default {
  name: 'Blood',
  components: {
    HistoryBar
  },
  props: ['queryData'],
  data() {
    return {
      current: current(),
      today: current(),
      valuepinchAvg: null, // 平均值-收压
      valuepinchMax: null, // 压力范围-最大值
      valuestretchAvg: null, // 平均值-舒压
      valuestretchMin: null, // 压力范围-最小值
      xData: [],
      stretchData: [], // 舒压
      pinchData: [], // 收压
      currentStretch: null,
      currentPinch: null,
      currentTime: null,
      historyXData: [],
      historyStretchData: [], // 历史舒压
      historyPinchData: [], // 历史收压
      historyPinchMax: 0, // 历史收压最大值
      historyPinchMin: 0, // 历史收压最小值
      historystretchMax: 0, // 历史舒压最大值
      historystretchMin: 0, // 历史舒压最小值
      currentActiveIndex: 0,
      showBoolPicker: false,
      // minDate: '',
      // maxDate: '',
      minDate: new Date(),
      // maxDate:new Date(),
      currentDate: new Date(),
      bloodList: ''
    }
  },
  mounted() {

    this.appSource()
    this.queryDatad = JSON.parse(this.queryData)
    this.getBloodData()
    this.getLastDay()
  },
  methods: {
    //判断安卓还是ios
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        this.minDate = new Date('1970/1/1')
      } else {
        this.minDate = new Date(1970, 1, 1)
      }
    },
    //判断数组中的数是否全部都为0
    isAllEqual(arr) {
      let one;
      if (arr.length != 0) {
        one = arr[0];
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== one) {
          return false
        }
      }
      return true
    },
    min() {
      let time = new Date();
      return `${2023},${Number(
        time.getMonth() + 1
      )},${Number(time.getDate())}`;
    },
    onConfirm(time) {
      var date = time;
      console.log(date)
      var seperator1 = "-";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      this.current = date.getFullYear() + seperator1 + month + seperator1 + strDate
      this.showBoolPicker = false;
      this.getBloodData()
    },
    newFunction(data) {
      console.log("88998899")
      console.log(data, data, 'data')
      const zeroIndexes = []
      data.forEach((item, index) => {
        if (item === 0) {
          zeroIndexes.push(index);
        }
      });
      // 遍历所有值为0的节点的索引数组
      zeroIndexes.forEach((zeroIndex) => {
        let prevNonZeroIndex = zeroIndex - 1;
        while (prevNonZeroIndex >= 0 && data[prevNonZeroIndex] === 0) {
          prevNonZeroIndex--;
        }
        if (prevNonZeroIndex >= 0) {
          let nextNonZeroIndex = zeroIndex + 1;
          while (nextNonZeroIndex < data.length && data[nextNonZeroIndex] === 0) {
            nextNonZeroIndex++;
          }
          if (nextNonZeroIndex < data.length) {
            const prevValue = data[prevNonZeroIndex];
            const nextValue = data[nextNonZeroIndex];
            const middleValue = (prevValue + nextValue) / 2;
            data[zeroIndex] = middleValue;
          }
        }
      });
    },
    isInteger(obj) {
      return obj % 1 === 0
    },
    getBloodData() {
      var that = this
      GetWatchTypeByDate({
        userId: this.queryDatad.userid,
        // userId: 'Mini_df80d757-6677-4cfe-8df7-e458e7684204',
        time: this.current,
        type: 2// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        // console.log(res.data.timelist[0].time,"9999999999999999999999999999999")
        if (res.data) {
          this.bloodList = res.data
          const _data = res.data
          this.valuepinchAvg = _data.valuepinchAvg
          this.valuepinchMax = _data.valuepinchMax
          this.valuestretchAvg = _data.valuestretchAvg
          this.valuestretchMin = _data.valuestretchMin
          this.xData = _data.timelist[0].time
          this.stretchData = _data.timelist[0].Stretch
          this.pinchData = _data.timelist[0].Pinchvalue
          console.log(this.stretchData, "this.stretchDatathis.stretchData")
          let PinchvalueShou = _data.timelist[0].Pinchvalue
          let StretchZhang = _data.timelist[0].Stretch
          this.stretchData.forEach((v, i) => {
            if (v != 0) {
              console.log(this.xData[i])
              this.currentStretch = v,
                this.currentPinch = this.pinchData[i]
              this.currentTime = this.xData[i]
              // console.log(this.currentTime, "=============")
            }
          })
          this.isAllEqual(this.stretchData)
          if (this.isAllEqual(this.stretchData) == true) {
            this.bloodList = null;
            this.valuepinchAvg = 0
            this.valuepinchMax = 0
            this.valuestretchAvg = 0
            this.valuestretchMin = 0
            this.currentStretch = '',
              this.currentTime = ''
            this.currentPinch = ''
            this.xData = ["00:00", "06:00", "12:00", "18:00", "23:45"]
            this.pinchData = ''
            this.stretchData = ''
            // return
          }
          that.newFunction(PinchvalueShou)
          that.newFunction(StretchZhang)
        } else {
          console.log("kong")
          this.bloodList = null;
          this.valuepinchAvg = 0
          this.valuepinchMax = 0
          this.valuestretchAvg = 0
          this.valuestretchMin = 0
          this.currentStretch = '',
          this.currentTime = ''
          this.currentPinch = ''
          this.xData = ["00:00", "06:00", "12:00", "18:00", "23:45"]
          this.pinchData = ''
          this.stretchData = ''
          // return
        }

        this.$nextTick(() => {
          this.bloodConfig()
        })
      }).catch(err => {
        this.xData = []
        this.stretchData = []
        this.pinchData = []
        // this.$nextTick(() => {
        //   this.bloodConfig()
        // })
      })
    },

    bloodConfig() {
      console.log(this.xData, "this.xData")
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('bloodecharts')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        grid: {
          left: '3%',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        color: ['#9566FF', '#FFC3B8'],
        legend: {
          data: ['Email', 'Union Ads']
        },
        tooltip: {
          trigger: 'axis',
          formatter: (value) => {
            console.log(value, "value")
            // if()
            const xIndex = value[0].dataIndex
            if (this.stretchData[xIndex] == 0 && this.pinchData[xIndex] == 0) {
              return
            } else {

              this.currentTime = this.xData[xIndex]
              console.log(this.isInteger(this.stretchData[xIndex]), "8888888888888888888888")
              if (this.isInteger(this.stretchData[xIndex]) == true && this.isInteger(this.pinchData[xIndex]) == true) {
                this.currentStretch = this.stretchData[xIndex]
                this.currentPinch = this.pinchData[xIndex]
              }

            }
          }
        },
        xAxis: [
          {
            // type: 'category',
            data: this.xData,
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              // interval:Math.ceil(this.xData.length / 4),
              interval: 0,
              margin: 20,
              formatter: (v, i) => {
                if (v === "00:00") {
                  return "00:00"
                } else if (v === "06:00") {
                  return "06:00"
                } else if (v === "12:00") {
                  return "12:00"
                } else if (v === "18:00") {
                  return "18:00"
                } else if (v === "23:45") {
                  return "24:00"
                }
                return ''
              },
            },
            splitNumber: 5
          }
        ],

        yAxis: [
          {
            type: 'value',
            // max: 200,
            // min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            max: 300,
            min: 0,
            splitNumber: 5
          }
        ],
        series: [
          // 第一条线
          {
            name: '舒张压',
            type: 'line',
            showSymbol: false, // 小圆点是否显示
            emphasis: {
              focus: 'series'
            },
            data: this.stretchData,
            symbolSize: 0, // 拐点的颜色
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.3
            },
            itemStyle: {
              // 图形区域颜色
              color: {
                // type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(149,102,255,0.6)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(149,102,255,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              normal: {
                label: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  }
                }
              }
            }
          },
          // 第二条线
          {
            name: '收缩压',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            color: '#FFC1B5',
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.2
            },
            stack: null,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,146,129,0.9)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,146,129,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              normal: {
                label: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  }
                }
              }
            },
            data: this.pinchData
          }
        ]
      }
      option && myChart.setOption(option)
      // myChart.on('finished', () => {
      //   this.$store.state.bbb = true
      //   console.log("执行2次");
      // })
    },
    onIndexCahnge(index) {
      this.currentActiveIndex = index
      // this.getHistoryBloodData()
    },
    getDate(flag) {
      const t = flag ? new Date(this.current).getTime() + 86400000 : new Date(this.current).getTime() - 86400000
      this.current = current(new Date(t))
      this.getBloodData()
    },
    handPoulp() {
      this.showBoolPicker = true
    },
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? '0' + month : month; //月份补 0
      var timer = [year, month, lastDate].join(",");
      this.maxDate = new Date(timer)
    }
  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
  height: 100%;
  padding: 0 20px;
  background-size: 100%;
  background-color: #f7f7f7;

  .content {
    width: 100%;
    border-radius: 15px;
    background: #fff;
    // height: 80%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .time {
      color: #868686;
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;

      .arr-left {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: -10px;
      }

      .arr-right {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) rotate(180deg);
        right: -10px;
      }

      &.top {
        // margin-bottom: 20px;
        display: flex;
        align-content: center;
      }

      .current {
        font-size: 20px;
        font-weight: 700;
        margin: 0 10px;
      }
    }

    .timing {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #868686;
      font-size: 28px;
      // margin: 20px 0;

      .num {
        color: #868686;
        font-size: 30px;
      }

      .number {
        color: #282828;
        // margin-top: 15px;
      }

      .millimeter {
        font-size: 20px;
        color: #868686;
      }
    }

    .charts {
      position: relative;
      width: 100%;

      .bloodecharts {
        // width: 300px;
        width: 100%;
        height: 240px;
      }

      .timenode {
        width: 90%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -20px;
        right: 0;
        color: #808080;
      }
    }

    /deep/ .history-tabbar {
      margin-bottom: 0;
    }

    .Tips {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      color: #4f4f4f;
      // color:#a990e5
      font-size: 14px;
      margin-top: 20px;

      .shrink,
      .diastole {
        position: relative;
      }

      .shrink::before,
      .diastole::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
      }

      .shrink::before {
        background: #db7d2b;
      }

      .diastole::before {
        background: #9566ff;
      }
    }

    .buttons {
      display: flex;
      margin-top: 30px;

      .btn {
        width: 155px;
        height: 65px;
        border: 1px solid #9566ff;
        background: #f3eeff;
        border-radius: 8px;

        .average,
        .pressure {
          position: relative;
          font-size: 5px;
          color: #858585;
          padding: 5px 20px;
        }

        .average::before,
        .pressure::before {
          content: "";
          width: 5px;
          height: 11px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 5px;
        }

        .average::before {
          background: #9566ff;
        }

        .pressure::before {
          background: #db7d2b;
        }

        .num {
          font-size: 20px;
          // margin: 8px 5px 5px 30px;
          padding: 8px 5px 5px 5px;
          text-align: center;
        }
      }

      .btn1 {
        margin-left: 5px;
        background-color: #fff0ee;
        border: 1px solid #ff9180;
      }

      .mercury {
        font-size: 10px;
        color: #858585;
      }
    }
  }
}
</style>
