<template>
    <div style="background-color: #f7f7f7;padding-top: 30px;padding-bottom:30px;">
      <!-- <div v-if="this.$store.state.aaa==false||this.$store.state.bbb==false" style="text-align: center;color: red;font-size: 50px;">加载中......</div> -->
        <div v-if="queryDataA.type==1">
            <HeartRateChild :queryData='queryData'></HeartRateChild>
            <div style="height: 30px; background-color: #f7f7f7;"></div>
            <BloodChild :queryData='queryData'></BloodChild>
        </div>
        <div v-if="queryDataA.type==2">
            <BloodChild :queryData='queryData'></BloodChild>
            <div style="height: 30px; background-color: #f7f7f7;"></div>
            <HeartRateChild :queryData='queryData'></HeartRateChild>
        </div>
    </div>
</template>
<script>
import HeartRateChild from './components/heartRateChild.vue'
import BloodChild from './components/bloodChild.vue'
export default {
  components: {
    HeartRateChild,
    BloodChild
  },
  data () {
    return {
      queryData: '',
      queryDataA: ''
    }
  },
  created () {
    this.queryDataA = this.$route.query
    this.queryData = JSON.stringify(this.$route.query)
    console.log('this.$route.query', this.$route.query)
    // console.log('this.queryData',this.queryData)
    console.log(this.$children,"++++++++++++++++++++++++++++++++++6")
    console.log(this.$store.state,"this.$store.state")
  }
}
</script>

<style lang="scss" scoped>

</style>
