<template>
  <div class='abilityDetails'>
    <div class="content">
      <!-- 时间 -->
      <div class="time top">
        <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)" />
        <div class="current" @click="handPoup">{{ current }}</div>
        <svg-icon v-show="current != today" icon-class="back" class="arr arr-right" @click="getDate(true)" />
      </div>
      <!-- 时间 -->
      <div class="percentage">
        <!-- <div class="num" style="text-align: center;">{{ currentHeartRate }}</div> -->
        <div style="font-size: 25px;color: #868686;text-align: center;">心率</div>
      </div>
      <div class="time">
        <div class="current">{{ currentTime }}</div>
      </div>

      <div class="percentage">
        <div class="num" style="text-align: center;">{{ currentHeartRate }}</div>
        <div>次/分钟</div>
      </div>
      <!-- 折线图 -->
      <!-- <div class="charts" style="height:300px;line-height: 300px;text-align: center;"
        v-if="!heartList && heartList == null">
        暂无数据
      </div> -->
      <div class="echart">
        <div id="BrokenLine" class="histogram"></div>
      </div>
      <!-- 提示 -->
      <div class="tipsNum ">
        <div class="mini">
          <div class="title">
            <div class="tipicon"></div>
            <div>最低值</div>
          </div>
          <div class="text">
            <div class="num">{{ valuemin }}</div>
            <div>次/分钟</div>
          </div>
        </div>
        <div class="average">
          <div class="title">
            <div class="tipicon"></div>
            <div>平均值</div>
          </div>
          <div class="text">
            <div class="num">{{ valueavg }}</div>
            <div>次/分钟</div>
          </div>
        </div>
        <div class="max">
          <div class="title">
            <div class="tipicon"></div>
            <div>最大值</div>
          </div>
          <div class="text">
            <div class="num">{{ valuemax }}</div>
            <div>次/分钟</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <van-calendar
      v-model="boolShowPicker"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
      @cancel="boolShowPicker = false"
      :style="{ height: '480rpx' }"
    /> -->
    <van-calendar v-model="boolShowPicker" :min-date="minDate" @confirm="onConfirm" color="#9966FF"
      :style="{ height: '420px' }" />
  </div>
</template>

<script>

import * as echarts from 'echarts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components'
import { mapGetters } from 'vuex'
import { LineChart } from 'echarts/charts'
import { formatDate } from '@/utils/prototype/date.js'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { current, formatDay } from '@/util/validate'
import HistoryBar from '../../../components/tabbar/HistoryBar.vue'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])

export default {
  components: {
    HistoryBar
  },
  data() {
    return {
      current: current(),
      today: current(),
      yData: [],
      xData: [],
      currentHeartRate: null,
      currentTime: null,
      valueavg: null, // 平均值
      valuemax: null, // 最大值
      valuemin: null, // 最小值
      currentActiveIndex: 0, // 当前tab index
      historyRateMax: 0, // 历史心率最大值
      historyRateMin: 0, // 历史心率最小值
      historyXData: [],
      historyYData: [],
      boolShowPicker: false,
      minDate: new Date(),
      currentDate: new Date(),
      heartList: '',
      chartsFinish:false
    }
  },
  props: ['queryData'],
  created() {

  },
  mounted() {
    // this.initChart()
    this.appSource()
    this.queryDatad = JSON.parse(this.queryData)
    this.getHeartRateData()
    // this.getHistoryHeartRateData()
    this.getLastDay()
    
  },
  methods: {

    // 判断安卓还是ios
    appSource() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.minDate = new Date('1970/1/1')
      } else {
        this.minDate = new Date(1970, 1, 1)
      }
    },
    //判断数组中的数是否全部都为0
    isAllEqual(arr) {
      let one;
      if (arr.length != 0) {
        one = arr[0];
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== one) {
          return false
        }
      }
      return true
    },
    handPoup() {
      this.boolShowPicker = true
    },
    onConfirm(time) {
      var date = time
      var seperator1 = '-'
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.current = date.getFullYear() + seperator1 + month + seperator1 + strDate
      this.boolShowPicker = false
      // this.getHeartRateData()
    },
    // 获取当前日期的数据
    getHeartRateData() {
      GetWatchTypeByDate({
        userId: this.queryDatad.userid,
        // userId: 'Mini_df80d757-6677-4cfe-8df7-e458e7684204',
        time: this.current,
        type: 1// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        console.log('res.data', res.data)
        if (res.data) {
          var aaa = res.data.timelist[0].value
          const zeroIndexes = [];
          aaa.forEach((item, index) => {
            if (item === 0) {
              zeroIndexes.push(index);
            }
          });
          // console.log(zeroIndexes, 'zeroIndexes')
          // 遍历所有值为0的节点的索引数组
          zeroIndexes.forEach((zeroIndex) => {
            let prevNonZeroIndex = zeroIndex - 1;
            while (prevNonZeroIndex >= 0 && aaa[prevNonZeroIndex] === 0) {
              prevNonZeroIndex--;
            }
            if (prevNonZeroIndex >= 0) {
              let nextNonZeroIndex = zeroIndex + 1;
              while (nextNonZeroIndex < aaa.length && aaa[nextNonZeroIndex] === 0) {
                nextNonZeroIndex++;
              }
              if (nextNonZeroIndex < aaa.length) {
                const prevValue = aaa[prevNonZeroIndex];
                const nextValue = aaa[nextNonZeroIndex];
                const middleValue = (prevValue + nextValue) / 2;
                aaa[zeroIndex] = middleValue;
              }
            }
            // console.log(aaa[zeroIndex], 'middleValue')
          });
          


          this.heartList = res.data
          const _data = res.data
          this.yData = _data.timelist[0].value
          this.xData = _data.timelist[0].time
          this.valueavg = _data.valueavg
          this.valuemax = _data.valuemax
          this.valuemin = _data.valuemin
          this.yData.forEach((v, i) => {
            if (v != 0) {
              this.currentHeartRate = v,
                this.currentTime = this.xData[i]
            }
          })
          if (this.isAllEqual(this.yData) == true) {
            this.heartList = null;
            this.valueavg = 0
            this.valuemax = 0
            this.valuemin = 0
            this.currentHeartRate = '',
              this.currentTime = '',
              this.xData = ["00:00", "06:00", "12:00", "18:00", "23:45"],
              this.yData = []
            // return
          }
        } else {
          console.log("kong")
          this.heartList = null;
          this.valueavg = 0
          this.valuemax = 0
          this.valuemin = 0
          this.currentHeartRate = '',
            this.currentTime = '',
            this.xData = ["00:00", "06:00", "12:00", "18:00", "23:45"],
            this.yData = []
          // return
        }
        this.$nextTick(() => {
          this.initializationBrokenLine()
        })
      }).catch(err => {
        this.yData = []
        this.xData = []
        this.$nextTick(() => {
          this.initializationBrokenLine()
        })
      })
    },
    // 初始化折线图
    initializationBrokenLine() {
      const _this = this
      if (!document.getElementById('BrokenLine')) {
        return
      }
      const chartDom = document.getElementById('BrokenLine')
      const myChart = echarts.init(chartDom)
     
      let option
      option = {
        tooltip: {
          
          animation:true,
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          axisPointer: {
            // type: "none"
            snap: true,
            // type: 'cross',
            animation: true,
            // label: {
            //   formatter: function (params) {
            //     // console.log(params,"params")
            //     var value = params.value;
            //     // 如果辅助线的值为0，则返回一个空字符串，否则返回辅助线的值
            //     return value === 0 ? '' : value;
            //   }
            // }
          },

          formatter: (value) => {
            console.log(value)
            const xIndex = value[0].dataIndex
            console.log(xIndex)
            if (this.yData[xIndex] !== 0 && Number.isInteger(this.yData[xIndex])==true) {
              this.currentHeartRate = this.yData[xIndex]
              this.currentTime = this.xData[xIndex]
            }

          }
        },

        xAxis: {
          axisTick: {
            show: false
          },

          axisLine: {
            show: false
          },
          type: 'category',
          data: this.xData,
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            // interval:Math.ceil(this.xData.length / 12),
            interval: 0,
            margin: 10,
            formatter: (v, i) => {
              if (v === "00:00") {
                return "00:00"
              } else if (v === "06:00") {
                return "06:00"
              } else if (v === "12:00") {
                return "12:00"
              } else if (v === "18:00") {
                return "18:00"
              } else if (v === "23:45") {
                return "24:00"
              }
              return ''
            },
          },
          splitNumber: 5
        },
        yAxis: {
          type: 'value',
          scale: true,
          max: 200,
          min: 0,
          splitNumber: 4,
          axisLine: {
            show: true
          },
          splitLine: {
            show: true,
            lineStyle: {
              // y轴线的样式
              type: 'dashed'
              // color:'#969798',
            }
          }
        },

        grid: {
          left: '10%',
          right: '7%',
          bottom: '20%'
        },
        series: [

          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.yData,
            showAllSymbol: true,
          }
        ]
      }
     
      option && myChart.setOption(option, true)
    //   myChart.on('finished',() => {
    //     console.log("执行一次");
    //     // this.$store.state.aaa = true
    // })
    //   this.myChart.on("mousemove", (params) => {
    //   // console.log(params);
    //   console.log(`你点击的横坐标是"${params.name}",纵坐标是"${params.data}"`);
    // });
    },
   
    // 获取历史心率数据

    getHistoryHeartRateData() {
      GetWatchTypeByHistoryDate({
        userId: this.queryDatad.userid,
        // userId: 'Mini_44c7f5ff-52a0-48c6-8c64-592c9f6fc5e9',
        timeType: this.currentActiveIndex + 1, // 1近一周 2近一个月 3近三个月 4近半年 5近一年
        dateTtpe: 1// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        // console.log('------',_data)
        const _list = _data.daysList.reverse()
        this.historyXData = _list.map(item => {
          return item.time
        })
        this.historyYData = _list.map(item => {
          return item.value
        })
        this.historyRateMax = _data.heartRateMax
        this.historyRateMin = _data.heartRateMin
        this.$nextTick(() => {
          this.initHistoryBrokenLine()
        })
      })
    },

    // 初始化历史折线图
    initHistoryBrokenLine() {
      if (!document.getElementById('HistoryBrokenLine')) {
        return
      }
      const chartDom = document.getElementById('HistoryBrokenLine')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.historyXData,
          nameLocation: "end",
          boundaryGap: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false }, // 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            margin: '20',
            formatter: (v, i) => {
              return this.currentActiveIndex == 0 || this.currentActiveIndex == 1 ? v.substring(5) : v
            }
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 3
        },
        grid: {
          left: '10%',
          right: '7%',
          bottom: '20%'
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.historyYData
          }
        ]
      }
      option && myChart.setOption(option, true)
    },
    onIndexCahnge(index) {
      this.currentActiveIndex = index
      // this.getHistoryHeartRateData()
    },
    getDate(flag) {
      const t = flag ? new Date(this.current).getTime() + 86400000 : new Date(this.current).getTime() - 86400000
      this.current = current(new Date(t))
      this.getHeartRateData()
    },
    getLastDay() {
      var year = new Date().getFullYear() // 获取年份
      var month = new Date().getMonth() + 1 // 获取月份
      var lastDate = new Date(year, month, 0).getDate() // 获取当月最后一日
      month = month < 10 ? '0' + month : month // 月份补 0
      var timer = [year, month, lastDate].join(',')
      this.maxDate = new Date(timer)
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>
<style lang='scss' scoped>
.abilityDetails {
  height: 100%;
  padding: 0 20px;
  background-size: 100%;
  background-color: #f7f7f7;

  .content {
    width: 100%;
    border-radius: 15px;
    background: #fff;
    // height: 80%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .time {
      color: #868686;
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;

      .arr-left {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: -10px;
      }

      .arr-right {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) rotate(180deg);
        right: -10px;
      }

      &.top {
        // margin-bottom: 20px;
        display: flex;
        align-content: center;
      }

      .current {
        font-size: 20px;
        font-weight: 700;
        margin: 0 10px;
      }
    }

    .echart {
      width: 100%;
      position: relative;

      .histogram {
        width: 100%;
        height: 240px;
        box-sizing: border-box;

        &.p-20 {
          padding: 0 20px;
        }

        // /deep/ canvas {
        //     width: 110% !important;
        //     height: 120% !important;
        //     left: -2% !important;
        // }
      }

      .timenode {
        width: 90%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 10px;
        right: 0;
        color: #808080;
      }
    }

    .percentage {
      display: flex;
      align-items: center;
      color: #858585;
      font-size: 20px;
      // margin-top: 20px;

      .num {
        color: #4F4F4F;
        font-size: 34px;
      }
    }

    .tipsNum {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .mini,
      .average,
      .max {
        width: 32%;
        padding: 5px 10px;
        border-radius: 5px;

        .title {
          display: flex;
          align-items: center;
          color: #808080;

          .tipicon {
            width: 5px;
            height: 10px;
            border-radius: 5px;
            margin-right: 5px;
          }
        }

        .text {
          color: #808080;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-size: 10px;

          .num {
            color: #282828;
            // font-size: 24px;
            font-size: 16px;
          }
        }

      }

      .mini {
        border: 1px solid #D330FF;
        background: #FEF8FE;

        .tipicon {
          background: #D330FF;
        }
      }

      .average {
        border: 1px solid #FCB334;
        background: #FFFBF5;

        .tipicon {
          background: #FCB334;
        }
      }

      .max {
        border: 1px solid #2FBE95;
        background: #F4FBF9;

        .tipicon {
          background: #2FBE95;
        }
      }
    }

    &.history-box {

      // padding: 20px 0;
      .tipsNum {
        // padding: 0 20px;
        box-sizing: border-box;
      }

      .mini,
      .max {
        width: 45%;
      }
    }

    // .history-tabbar {
    //     width:100%;
    //     display: flex;
    //     justify-content: space-evenly;
    //     font-size: 15px;
    //     margin-bottom: 20px;

    //     .tab {
    //         // width: 55px;
    //         // padding: 8px;
    //         color: #868686;
    //         padding:5px 10px;
    //         &.active{
    //             border-radius: 20px;
    //             text-align: center;
    //             background: #ffe6e2;
    //             color: #ff9180;
    //             // padding: 10px 15px;
    //         }
    //     }
    // }
  }
}
</style>
